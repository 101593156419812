import React from "react";
import ivplogo from "../assets/IVP-logo-white-300x119.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-top">
          <div className="col-sm-12">
            <a href="https://www.ivp.lt">
              <img src={ivplogo} alt="IVP Partners" className="footer-logo" />
            </a>
            <div>
              Mes rūpinamės Jūsų informacijos apsauga ir kviečiame susipažinti
              su aktualiais dokumentais:
            </div>
            <div>
              <a
                href="/informacija_klientui.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer"
              >
                Informacija draudėjui
              </a>
              <a
                href="/duomenu_subjekto_atmintine.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer"
              >
                Duomenų subjekto atmintinė
              </a>
              <a
                href="/slapuku_politika.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer"
              >
                Slapukų politika
              </a>
              <a
                href="/tvarumo_politika.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer"
              >
                Tvarumo politika
              </a>
              <a
                href="/Informacijos_saugumo_politika.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer"
              >
                Informacijos saugumo politika
              </a>
            </div>
          </div>
        </div>
        <div className="row footer-main">
          <div className="col-sm-4">
            <div className="box widget_text_icl slz-widget ">
              <div className="footer-title">SUSISIEKIME:</div>
              <div className="textwidget">
                <p>
                  Pagrindinė būstinė:
                  <br />
                  Ulonų g. 2, LT-08245 Vilnius
                  <br />
                  Tel. +370 5 2197601
                </p>
                <p>
                  Filialas Klaipėdoje:
                  <br />
                  Šilutės pl. 2-539, LT-91111 Klaipėda
                  <br />
                  Tel. +370 46 313049
                </p>
                <p>info@ivp.lt</p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="footer-title">DARBO VALANDOS:</div>
            <div className="textwidget">
              <p>
                Pietūs: 11:30 – 12:30
                <br />
                Pirmadienis – penktadienis: 8:00 – 17:00
                <br />
                Savitarna: 24/7
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="footer-title">REKVIZITAI:</div>
            <div className="textwidget">
              <p>
                UADBB IVP Partners
                <br />
                Įmonės kodas 302489781
              </p>
              <p>
                Registracijos adresas:
                <br />
                Ulonų g. 2, LT-08245 Vilnius
                <br />
                Lietuvos Respublika
              </p>
              <p>
                Bankas SEB bankas
                <br />
                A/s LT47 7044 0600 0737 5520
                <br />
                Nėra PVM mokėtojas
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
